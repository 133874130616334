// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito:wght@0,600;0,800;1,300&display=swap');

// Variables
@import '_variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Easy editor html editor
@import '~easyeditor/src/easyeditor.css';

// Bootstrap Icons
@import '~bootstrap-icons/font/bootstrap-icons';

// Custom side modals
@import 'side-modals';

// Abstract styles
@import 'customisations';

//Animations
@import 'animation';